.swiper {
    width: 100%;
    /* height: 100%; */
  }
  
  .swiper-slide {
    /* text-align: center; */
    font-size: 10px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
  }
  
  /* .swiper-slide {
    width: 60%;
  }
  
  .swiper-slide:nth-child(2n) {
    width: 20%;
  }
  
.swiper-slide:nth-child(3n) {
    width: 20%;
} */
  
.logo {
    width: 13.9375rem;
    height: 3rem;
    flex-shrink: 0;
  
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #120000;
    stroke-width: 4;
    stroke: #120000;
    font-family: 'Poetsen One';
    paint-order: stroke fill;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  
    background: linear-gradient(0deg, #FAFFBE 0%, #FAFF00 51.33%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.menu-selected {
    flex-shrink: 0;
    border-radius: 0.6875rem;
    border: 1px solid #391100;
    background: linear-gradient(15deg, rgba(62, 26, 0, 0.52) 23.58%, rgba(255, 136, 50, 0.52) 91.1%);
    box-shadow: -6px 7px 1.7px -2px rgba(168, 123, 108, 0.38), -78px 67px 9.9px 0px rgba(0, 0, 0, 0.55) inset, 2px -2px 15.9px 0px #311100 inset;
}



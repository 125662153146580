.swiper {
    width: 100%;
    /* padding-top: 50px; */
    /* padding-bottom: 50px; */
}


.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 'auto';
}

@media (max-width: 576px) {
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 200px;
        height: 'auto';
    }
}

  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .mySwiper{
    /* width: 1052px; */
  }
.title-box {
    color: #F90;

    text-align: center;
    text-shadow: 0px 4px 0px #120000;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #120000;
    font-family: Poetsen One;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}